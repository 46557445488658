import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { LeftArrow, RightArrow } from "../common/icons"

const Gallery = ({ selected, images }) => {
  const [mobileIndex, setMobileIndex] = useState(0)
  const [image, setImage] = useState(selected)
  useEffect(() => {
    setImage(selected)
  }, [selected])
  return (
    <div className="w-full flex md:hidden flex-col gap-4 bg-gray-100 py-8">
      <GatsbyImage
        alt={image.altText || ""}
        className="w-full aspect-square min-w-full rounded-xl overflow-hidden bg-white"
        imgClassName="object-contain w-full"
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
      <div className="flex w-full gap-2 text-gray-700">
        <button
          className="h-full flex items-center w-6 disabled:text-gray-300 hover:text-sky-500 focus-visible:text-sky-500 transition-colors duration-300"
          onClick={() => setMobileIndex(mobileIndex - 1)}
          disabled={0 >= mobileIndex}
        >
          <LeftArrow />
        </button>
        <div className="grid grid-cols-3 gap-2">
          {images.map(
            (
              {
                altText,
                shopifyId,
                localFile: {
                  childImageSharp: { gatsbyImageData },
                },
              },
              index
            ) => (
              <button
                key={index}
                className={mobileIndex <= index && mobileIndex + 3 > index ? "" : "hidden"}
                onClick={() => {
                  setImage(images[index])
                }}
              >
                <GatsbyImage
                  alt={altText || ""}
                  className={`w-full min-w-full aspect-square rounded-xl ${
                    shopifyId === image.shopifyId || shopifyId === image.id
                      ? "border-2"
                      : "hover:border focus-visible:border"
                  } border-sky-500 overflow-hidden bg-white`}
                  imgClassName="object-contain w-full"
                  image={gatsbyImageData}
                />
              </button>
            )
          )}
        </div>
        <button
          className="h-full flex items-center w-6 disabled:text-gray-300 hover:text-sky-500 focus-visible:text-sky-500 transition-colors duration-300 "
          onClick={() => {
            setMobileIndex(mobileIndex + 1)
          }}
          disabled={images.length - 3 <= mobileIndex}
        >
          <RightArrow />
        </button>
      </div>
    </div>
  )
}

export default Gallery
