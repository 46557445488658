import { graphql, navigate } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../../components/common/layout"
import HeadGenerator from "../../components/common/seo"
import ProductDetailContent from "../../components/product/detail"
import data from "../../data/fields"
import { getProductUrl } from "../../util/data"

const ProductDetailPage = ({ data: { product } }) => {
  useEffect(() => {
    if (!product) navigate("/")
  }, [product])

  return <Layout>{product && <ProductDetailContent product={product} />}</Layout>
}

export default ProductDetailPage

export const Head = ({ data: { product } }) => {
  if (!product)
    return (
      <HeadGenerator
        data={{
          title: "Jockey On Ice",
          description: "Descubre la magia del hielo en el Jockey Club",
          url: data.url.index,
        }}
        goHome
      />
    )
  return (
    <HeadGenerator
      data={{
        title: product.title,
        description: product.description,
        url: getProductUrl(product.handle),
        image: {
          srcWebp:
            product.featuredImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
          width: product.featuredImage.localFile.childImageSharp.gatsbyImageData.width,
          height: product.featuredImage.localFile.childImageSharp.gatsbyImageData.height,
          altText: product.featuredImage.altText,
        },
      }}
    />
  )
}

export const query = graphql`
  query ($id: String!) {
    product: shopifyProduct(id: { eq: $id }, tracksInventory: { eq: true }) {
      id
      shopifyId
      handle
      title
      description
      tags
      options {
        name
        values
      }
      collections {
        title
        description
      }
      featuredImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(formats: [WEBP], width: 630)
          }
        }
      }
      images {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP], sizes: "1200w 500w 200w 100w")
          }
        }
        shopifyId
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        availableForSale
        sku
        title
        price
        compareAtPrice
        inventoryQuantity
        selectedOptions {
          name
          value
        }
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP], sizes: "500w 200w 100w")
            }
          }
          id
        }
        shopifyId
        product {
          shopifyId
        }
      }
      vendor
      tracksInventory
    }
  }
`
