import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Close } from "../icons"

const types = {
  success: {
    border: "border-sky-500",
    buttonBorder: "hover:border-sky-500",
    bg: "hover:bg-sky-50",
    buttonColor: "hover:text-sky-500",
  },
  error: {
    border: "border-red-500",
    buttonBorder: "hover:border-red-500",
    bg: "hover:bg-red-50",
    buttonColor: "hover:text-red-500",
  },
}

export const Toast = ({ children, type, onHide, duration = 5000 }) => {
  const [start, setStart] = useState(false)
  useEffect(() => {
    setTimeout(() => setStart(true), 100)
  }, [])
  useEffect(() => {
    if (!start) return
    const timeout = setTimeout(() => {
      setStart()
      setTimeout(() => onHide(), 300)
    }, duration)
    return () => clearTimeout(timeout)
  }, [start, duration, onHide])
  return (
    <div
      className={`fixed p-2 top-0 right-0 transition-transform duration-300 ${
        start ? "" : "translate-x-full"
      }`}
    >
      <div
        className={`relative flex items-center gap-4 z-50 rounded-lg p-4 border bg-white ${
          types[type]?.border || ""
        }`}
      >
        <div>{children}</div>
        <button
          onClick={() => {
            setStart()
            setTimeout(() => onHide(), 300)
          }}
          className={`p-1 border rounded-lg transition-colors duration-300 ${
            types[type]?.buttonBorder || ""
          } ${types[type]?.buttonColor || ""} ${types[type]?.bg || "bg-white"}`}
        >
          <Close />
        </button>
      </div>
    </div>
  )
}
