import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, useContext } from "react"
import { PastelButton } from "../common/button"
import { Cart, Check, Loader, Warning } from "../common/icons"
import { LayoutSection, PriceLayout } from "../common/layout"
import { Counter, SelectShowDisabledOptions } from "../common/selection"
import { StoreContext } from "../../context/store-context"
import { SimplePaddedCard } from "../common/cards"
import { Toast } from "../common/toasts"
import { Link } from "gatsby"
import data from "../../data/fields"
import { useEffect } from "react"
import { getProductStock } from "../../util/data"
import { useInView } from "react-intersection-observer"
import { useRef } from "react"
import Gallery from "./gallery"

const ProductDetailContent = ({ product }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [variant, setVariant] = useState()
  const variantSelectors = {}
  for (let option of product.options) variantSelectors[option.name] = new Set(option.values)
  const [stocks, setStocks] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [image, setImage] = useState(product.variants[variant?.index]?.image || product.images[0])
  const { addVariantToCart, loading, didJustAddToCart, checkout } = useContext(StoreContext)
  const start = useRef()
  const end = useRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })

  const imageIndexes = {}
  for (let i = 0; i < product.images.length; i++) imageIndexes[product.images[i].shopifyId] = i

  const getValidOptions = (currentVariant, targetOption) => {
    const variants = product.variants.filter(variant => {
      if (
        product.options.length === 1 &&
        (!variant.availableForSale || variant.inventoryQuantity <= 0)
      )
        return false
      let count = 1
      for (let option of variant.selectedOptions)
        if (targetOption !== option.name && currentVariant?.[option.name] === option.value) count++
      return count === variant.selectedOptions.length
    })

    const values = new Set()
    variants.forEach(variant =>
      variant.selectedOptions.forEach(
        option => option.name === targetOption && values.add(option.value)
      )
    )

    return values
  }

  const changeVariant = (targetValue, targetOption) => {
    for (let i = 0; i < product.variants.length; i++) {
      const options = product.variants[i].selectedOptions
      if (
        options.every(({ name, value }) =>
          name === targetOption ? value === targetValue : value === variant[name]
        )
      ) {
        setImage(product.variants[i].image || product.images[0])

        const imageId = product.variants[i].image?.id || product.images[0].shopifyId
        const imageIndex = imageIndexes[imageId]

        if (imageIndex === product.images.length - 1)
          end?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          })
        else if (imageIndex > 0)
          document.getElementById(imageId)?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        else if (imageIndex === 0 && !inView)
          start?.current?.scrollIntoView({
            behavior: "smooth",
          })

        return setVariant({
          ...variant,
          [targetOption]: targetValue,
          index: i,
          id: product.variants[i].shopifyId,
        })
      }
    }
  }

  const validateStock = () => {
    const item = checkout.lineItems.filter(item => item.variant.id === variant.id)
    if (item.length === 0) item[0] = { quantity: 0 }

    if (stocks[variant.id] < item[0].quantity + quantity)
      setError("Ya añadiste todo el stock del producto a tu carrito")
    return (
      product.variants[variant.index].availableForSale &&
      product.variants[variant.index].inventoryQuantity > 0 &&
      stocks[variant.id] >= item[0].quantity + quantity
    )
  }

  useEffect(() => {
    getProductStock(product.shopifyId).then(stocks => {
      const result = {}
      for (let variant of stocks) result[variant.id] = variant.stock
      setStocks(result)
    })
  }, [product.shopifyId])

  useEffect(() => {
    const preFound = product.variants.map(
      (variant, index) =>
        variant.availableForSale && variant.inventoryQuantity > 0 && { ...variant, i: index }
    )
    const preFoundAvailable = preFound.filter(item => item)
    const postFound = preFound.findIndex(
      variant => variant.image?.id === product.images[0].shopifyId
    )
    const found = preFoundAvailable.length
      ? preFoundAvailable.length > 1
        ? postFound >= 0
          ? postFound
          : preFoundAvailable[0].i
        : preFoundAvailable[0].i
      : 0
    const index = found >= 0 ? found : 0
    const firstVariant = { index, id: product.variants[index].shopifyId }

    for (let option of product.variants[firstVariant.index].selectedOptions)
      firstVariant[option.name] = option.value
    setVariant(firstVariant)
  }, [product.images, product.variants])

  const options = product.options
    .map(({ name, values }, i) => {
      if (name === "Title" || variantSelectors[name][0] === "Default Title") return false
      const validOptions = getValidOptions(variant, name) || new Set()
      const options = values.map(value => ({
        label: value,
        disabled: !validOptions.has(value),
      }))
      return (
        options.length > 0 && (
          <SelectShowDisabledOptions
            key={i}
            title={name}
            options={options}
            value={variant?.[name]}
            setValue={value => changeVariant(value, name)}
          />
        )
      )
    })
    .filter(option => option)

  return (
    <LayoutSection>
      <div className="w-full flex flex-col items-center bg-gray-100">
        <div ref={ref}></div>
        <section className="w-full min-h-full flex flex-col md:flex-row max-w-lg md:max-w-5xl justify-center md:gap-4 px-4">
          <div className="w-7/12 hidden md:flex flex-col gap-4 bg-gray-100 py-8" ref={start}>
            {product.images.map(
              (
                {
                  shopifyId,
                  altText,
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
                index
              ) => {
                return (
                  <GatsbyImage
                    id={shopifyId}
                    key={index}
                    alt={altText || ""}
                    className={`w-full aspect-square object-contain min-w-full bg-white rounded-xl border-2 ${
                      product.variants.length > 1 &&
                      product.variants[variant?.index]?.image?.id === shopifyId
                        ? "border-sky-500"
                        : "border-transparent"
                    }`}
                    imgClassName={`rounded-xl object-contain`}
                    image={gatsbyImageData}
                  />
                )
              }
            )}
          </div>
          <Gallery selected={image} images={product.images} setSelected={setImage} />
          <div className="w-full md:w-5/12">
            <div className="md:sticky top-0 flex flex-col gap-4 pb-8 md:pt-8">
              <SimplePaddedCard>
                <h2 className="text-xl font-bold">{product.title}</h2>
                <PriceLayout
                  original={product.variants[variant?.index]?.price * quantity || 0}
                  reduced={product.variants[variant?.index]?.compareAtPrice * quantity || 0}
                />
                {options.length > 0 && <div className="flex flex-col gap-3 pt-4">{options}</div>}
                <div className="flex flex-col xs:flex-row md:flex-col lg:flex-row w-full gap-2 items-start pt-8">
                  <div className="w-full">
                    <Counter
                      min={1}
                      max={stocks[variant?.id]}
                      value={quantity}
                      setValue={setQuantity}
                    />
                  </div>
                  <PastelButton
                    onClick={() => {
                      validateStock() &&
                        addVariantToCart(product.variants[variant.index].shopifyId, quantity)
                          .then(() => setSuccess(true))
                          .catch(() => setError("El producto ya no se encuentra en stock"))
                    }}
                    disabled={quantity > stocks[variant?.id]}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      {quantity > stocks[variant?.id] ? (
                        <>
                          <Warning />
                          <span>
                            {stocks[variant?.id] === 1
                              ? "Queda 1"
                              : stocks[variant?.id] > 1
                              ? `Quedan ${stocks[variant?.id]}`
                              : "Agotado"}
                          </span>
                        </>
                      ) : loading ? (
                        <div className="w-6 h-6">
                          <Loader />
                        </div>
                      ) : didJustAddToCart ? (
                        <>
                          <Check />
                          <span>Añadido</span>
                        </>
                      ) : (
                        <>
                          <Cart />
                          <span>Añadir</span>
                        </>
                      )}
                    </div>
                  </PastelButton>
                </div>
              </SimplePaddedCard>
              <SimplePaddedCard>
                <div className="flex flex-col gap-1">
                  <h2 className="text-lg font-bold underline underline-offset-4">Descripción</h2>
                  <p>{product.description}</p>
                </div>
                <div className="flex flex-col text-sm gap-1 pt-4">
                  <h2 className="text-lg font-bold underline underline-offset-4">
                    Especificaciones
                  </h2>
                  <span>Marca: {product.vendor}</span>
                  <div className="flex justify-between">
                    <span>Tipo: {product.collections.map(({ title }) => title).join(", ")}</span>
                    <span>SKU: {product.variants[variant?.index]?.sku}</span>
                  </div>
                </div>
              </SimplePaddedCard>
            </div>
          </div>
        </section>
        <div className="hidden md:block" ref={end}></div>
      </div>
      {error && (
        <Toast type="error" onHide={() => setError(false)}>
          {error}
        </Toast>
      )}
      {success && (
        <Toast type="success" onHide={() => setSuccess(false)}>
          <div className="flex flex-col justify-center">
            <span>Producto añadido.</span>
            <Link to={data.url.carrito} className="flex w-full justify-center text-sky-500 gap-1">
              <span className="underline">Ir al carrito</span>
              <span className="text-sm">
                <Cart />
              </span>
            </Link>
          </div>
        </Toast>
      )}
    </LayoutSection>
  )
}

export default ProductDetailContent
